a {
    text-decoration: none;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default !important;
}

textarea {
    resize: vertical;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

a {
    color: white;
}
a:hover, a:visited {
    color: #ddd;
}
a:hover {
    color: #FFB84B;
    text-decoration: underline;
}

.table {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(255, 255, 255, 0.064);
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    flex-shrink: 0;
}

.table-row {
    display: flex;
    flex-direction: row;
    padding: 2px;
}

.table-item {
}
.table-item.release-phase {
    text-transform: capitalize;
}
.table-item.release-phase span {
    color: #01d28e;
}

code, .code {
    display: inline-flex;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 2px 4px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.75);
    line-height: 1.5;
    font-size: 12px;
    -webkit-text-size-adjust: 100%;
}

code.snippet, .code.snippet {
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: scroll;
    padding: 16px;
    /*border-radius: 12px;*/
}

.scrollable {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
}
.scrollable.x {
    /*overflow-x: scroll;*/
    overflow-y: hidden;
}
.scrollable.y {
    overflow-x: hidden;
    /*overflow-y: scroll;*/
}
.scrollable.both {
    /*overflow-x: overlay;*/
    /*overflow-y: overlay;*/
}

.scrollable:hover {
}

.scrollable::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.scrollable:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.scrollable:hover::-webkit-scrollbar-track {
}
.scrollable:hover::-webkit-scrollbar-thumb {
}
.scrollable:hover::-webkit-scrollbar-thumb:hover {
}
.scrollable::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
}
.scrollable::-webkit-scrollbar-track {
    border-radius: 5px;
    box-shadow: inset 0 0 5px 5px rgba(255, 255, 255, 0.1);
    border: solid 2px transparent;
}
.scrollable::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px 5px rgba(255, 255, 255, 0.2);
    border: solid 2px transparent;
}
.scrollable::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 5px 5px rgba(255, 255, 255, 0.3);
}

.animate-height-toggle.closed {
    margin-top: 0;
    padding-top: 0;
    /*min-height: 0;*/
    /*max-height: 0;*/
    min-height: 0;
    max-height: 0;
    opacity: 0;
    transition: margin-top 200ms, min-height 200ms, max-height 200ms, opacity 200ms;
    border-top: 1px solid transparent;
    pointer-events: none;
}

.animate-height-toggle.open {
    margin-top: 8px;
    padding-top: 8px;
    /*min-height: 93px;*/
    /*max-height: 300px;*/
    min-height: 32px;
    max-height: 220px;
    opacity: 1;
    transition: margin-top 200ms, min-height 200ms, max-height 200ms, opacity 200ms;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.whats-new.markdown {
    font-size: 14px;
    gap: 2px;
}
.whats-new h1 {
    font-size: 22px;
}
.whats-new h2 {
    font-size: 18px;
}
.whats-new h3 {
    font-size: 16px;
}
.whats-new h4 {
    font-size: 14px;
}
.whats-new h5 {
    font-size: 13px;
}
.whats-new blockquote {
    padding-left: 8px;
    border-left: 3px solid rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    margin: 8px 0;
    color: rgba(255, 255, 255, 0.48);
}
.whats-new ul {
    margin: 6px 0;
    padding-inline-start: 24px;
}
.whats-new li {
    color: rgba(255, 255, 255, 0.64);
    margin: 6px 0;
}
.whats-new li::marker {
    color: rgba(255, 255, 255, 0.64);
}
.whats-new a {
    color: #e3b073;
}
.whats-new p {
    font-size: 14px;
}

/* mobile resolution */
@media only screen and (max-width: 549px) {
    .whats-new li {
        margin: 12px 0;
        margin-inline-start: 16px;
    }
}
